/* ===================== Subscribe ======================*/

.subscribe-section {
	position: relative;
	overflow: hidden;
	z-index: 1;
	background: #f3f9ff; /* Set background to white */
	padding: 0px 0;
  }
  
  .subscribe-box {
	text-align: center;
	padding: 20px;
  }
  
  .subscribe-box .section-title h2 {
	position: relative;
	z-index: 1;
	color: #1c2045;
	text-transform: capitalize;
	font-size: 36px;
	font-weight: 700;
	margin-top: 20px; /* Add margin to separate from poster */
  }
  
  .available-title h3 {
	color: #1c2045;
	font-size: 24px;
	font-weight: 300;
	margin-top: 20px; /* Add margin to separate from section title */
  }
  
  .poster-image {
	display: flex;
	justify-content: center;
	margin: 2rem 0;
	border-radius: 15px;
  }
  
  .poster-image img {
	width: 300px; /* Increased by 20% from 250px */
	height: 450px; /* Increased by 20% from 375px */
	object-fit: cover;
	box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.2);
  }
  
  /* Style for screens wider than 768px */
  @media (min-width: 769px) {
	.poster-image img {
	  width: 300px; /* Increased by 20% from 250px */
	  height: 450px; /* Increased by 20% from 375px */
	}
  }
  
  /* Style for screens smaller than 768px */
  @media (max-width: 768px) {
	.poster-image img {
	  width: 240px; /* Increased by 20% from 200px */
	  height: 360px; /* Increased by 20% from 300px */
	}
  }
  
  .button-container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
	flex-wrap: wrap;
  }
  
  .platform-logo {
	width: auto; 
	height: 40px; /* Adjust the height to scale correctly */
	margin: 0 10px;
	transition: transform 0.3s, filter 0.3s;
	filter: brightness(1); /* Ensure logos are not initially dim */
  }
  
  .platform-logo:hover {
	transform: scale(1.1);
	filter: brightness(1.2); /* Slightly brighten the logos on hover */
  }
  
  /* Responsive styles */
  @media (max-width: 1200px) {
	.platform-logo {
	  height: 30px;
	  margin: 5px 15px; /* Increase margin for better spacing */
	}
  }
  
  @media (max-width: 992px) {
	.platform-logo {
	  height: 25px;
	  margin: 5px 10px; /* Increase margin for better spacing */
	}
  }
  
  @media (max-width: 768px) {
	.platform-logo {
	  height: 20px;
	  margin: 5px 10px; /* Increase margin for better spacing */
	}
  }
  
  @media (max-width: 576px) {
	.platform-logo {
	  height: 28px;
	  margin: 5px 8px; /* Increase margin for better spacing */
	}
  
	.poster-image img {
	  width: 200px; /* Adjust size for smaller screens */
	  height: 300px; /* Adjust size for smaller screens */
	}
  }
  