/* ===================== HERO ======================*/

.hero-section {
	position: relative;
	overflow: hidden;
	background: #ffffff none repeat scroll 0 0;
	padding-top: 50px;
	padding-bottom: 50px;
	z-index: 1;
  }
  
  .hidden-shape {
	display: none;
  }
  
  .hero-left h2 {
	font-weight: 700;
	color: #00163f;
	font-size: 36px;
	line-height: 1.2;
	margin-top: 20px;
	text-align: center;
  }
  
  .hero-left h2 span {
	display: block;
	position: relative;
	z-index: 1;
  }
  
  .underline {
	position: relative;
  }
  
  .hero-left h2 span.underline {
	display: inline-block;
  }
  
  .hero-left h2 span.underline:after {
	position: absolute;
	content: "";
	left: 0;
	right: 0;
	height: 16px;
	height: 1rem;
	bottom: 7px;
	z-index: -1;
	background-color: #ffffff;
	width: auto;
	opacity: 0.4;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
  }
  
  .hero-right {
	position: relative;
	z-index: 1;
  }
  
  .hero-video-container {
	position: relative;
	width: 100%;
  }
  
  .thumbnail {
	width: 100%;
	border-radius: 30px;
  }
  
  .video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 30px;
	opacity: 0;
	transition: opacity 0.3s;
	object-fit: cover;
  }
  
  .hero-video-container:hover .video {
	opacity: 1;
  }
  
  .hero-dot-shape {
	width: 230px;
	position: absolute;
	left: -70px;
	bottom: -55px;
	z-index: -1;
  }
  
  .hero-dot-shape img {
	width: 100%;
  }
  
  .available-on {
	text-align: center;
	margin-top: 20px;
  }
  
  .available-on span {
	display: block;
	font-size: 20px;
	font-weight: normal; /* Not bold */
	margin-bottom: 10px;
  }
  
  .buttons {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
  }
  
  .platform-logo {
	width: auto;
	height: 40px;
	margin: 10px;
	transition: transform 0.3s, filter 0.3s;
	filter: brightness(1);
  }
  
  .platform-logo:hover {
	transform: scale(1.1);
	filter: brightness(1.2);
  }
  
  /* Responsive styles */
  @media (max-width: 1200px) {
	.platform-logo {
	  height: 30px;
	}
  }
  
  @media (max-width: 992px) {
	.platform-logo {
	  height: 25px;
	}
  }
  
  @media (max-width: 768px) {
	.hero-left h2 {
	  font-size: 28px;
	}
  
	.platform-logo {
	  height: 20px;
	}
  
	.thumbnail, .video {
	  border-radius: 15px;
	}
  
	.hero-section {
	  padding-top: 50px;
	  padding-bottom: 100px;
	}
  }
  
  @media (max-width: 576px) {
	.hero-left h2 {
	  font-size: 24px;
	}
  
	.platform-logo {
	  height: 16px;
	}
  
	.thumbnail, .video {
	  border-radius: 10px;
	}
  
	.hero-section {
	  padding-top: 50px;
	  padding-bottom: 150px; /* Increase bottom padding for more space */
	}
  
	.hero-section .row {
	  margin-bottom: 40px; /* Add more space between rows */
	}
  
	.buttons {
	  flex-direction: row; /* Ensure logos are in a line */
	}
  }
  