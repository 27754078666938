/* ===================== ABOUT ======================*/

.about-section {
	background: #f6faff none repeat scroll 0 0;
	position: relative;
  }
  
  .about-top-shape {
	position: absolute;
	bottom: 0;
	left: 0;
	-webkit-transform: rotate(-90deg);
	transform: rotate(-90deg);
  }
  
  .about-bottom-shape {
	position: absolute;
	right: 0;
	bottom: 0;
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
  }
  
  .about-bottom-shape-right {
	position: absolute;
	top: 0;
	right: 0;
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);
  }
  
  .about-left h3 {
	font-size: 44px;
	font-weight: 700;
	color: #1c2045;
	margin-bottom: 20px;
  }
  
  .about-right img {
	border-radius: 10px;
	margin-bottom: 20px;
  }
  
  .about-top .about-left {
	padding-right: 5%;
  }
  
  .about-bottom .about-left {
	padding-left: 5%;
  }
  
  .section_100 {
	padding: 100px 0;
  }
  
  .section_b_100 {
	padding-bottom: 100px;
  }
  