/*--------------------------------------------------------------
#0.1    Theme Reset Style
--------------------------------------------------------------*/
@import url('https://fonts.googleapis.com/css?family=Poppins:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700;900&display=swap');

body {
	font-family: 'Poppins', sans-serif;
	font-weight: normal;
	font-style: normal;
	font-size: 15px;
	line-height: 1.7;
	color: #3a505f;
}

html,
body {
	height: 100%;
}

img {
	max-width: 100%;
	height: auto
}

p {
	font-size: 15px;
	color: #3a505f;
	line-height: 26px;
	font-family: 'Poppins', sans-serif;
	margin: 0;
	font-weight: 400;
	letter-spacing: 1px;
}

.no-pad-left {
	padding-left: 0
}

.no-pad-right {
	padding-right: 0
}

.no-pad-all {
	padding: 0
}

.fix {
	overflow: hidden
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	padding: 0;
	font-weight: 400;
	font-family: 'Source Sans Pro', sans-serif;
}

a {
	-webkit-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
	color: #6b739c;
	text-decoration: none;
}

a:focus {
	text-decoration: none;
	outline: medium none;
	color: inherit
}

a:hover {
	color: inherit;
	text-decoration: none;
	color: inherit
}

input:focus,
textarea:focus,
button:focus,
select:focus {
	outline: medium none
}

:-moz-placeholder {
	color: #7c8a97;
	text-transform: capitalize;
}

::-moz-placeholder {
	color: #7c8a97;
	text-transform: capitalize;
}

:-ms-input-placeholder {
	color: #7c8a97;
	text-transform: capitalize;
}

::-webkit-input-placeholder {
	color: #7c8a97;
	text-transform: capitalize;
}

:-ms-select-placeholder {
	color: #7c8a97;
	text-transform: capitalize;
}

::-webkit-select-placeholder {
	color: #7c8a97;
	text-transform: capitalize;
}

:-ms-textarea-placeholder {
	color: #7c8a97;
	text-transform: capitalize;
}

::-webkit-textarea-placeholder {
	color: #7c8a97;
	text-transform: capitalize;
}

tbody,
tfoot,
thead,
tr,
th,
td {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

ul,
ol {
	margin: 0;
	padding: 0;
}

li {
	list-style-type: none;
}

.theme-btn {
	position: relative;
	background: #000000 none repeat scroll 0 0;
	color: #fff;
	font-weight: 500;
	display: inline-block;
	padding: 12px 65px 12px 30px;
	text-transform: capitalize;
	border-radius: 30px;
	overflow: hidden;
}

.theme-btn span {
	position: absolute;
	height: 33px;
	width: 33px;
	right: 9px;
	top: 9px;
	background-color: white;
	color: #080808;
	text-align: center;
	line-height: 32px;
	padding: 0;
	border-radius: 50%;
}

.theme-btn-white {
	position: relative;
	background: #fff none repeat scroll 0 0;
	color: #c9c9c9;
	font-weight: 500;
	display: inline-block;
	padding: 12px 65px 12px 30px;
	text-transform: capitalize;
	border-radius: 30px;
	overflow: hidden;
}

.theme-btn-white span {
	position: absolute;
	height: 33px;
	width: 33px;
	right: 9px;
	top: 9px;
	background-color: #a7a7a7;
	color: #fff;
	text-align: center;
	line-height: 35px;
	padding: 0;
	border-radius: 50%;
}

.theme-btn:hover {
	background: #7e7e7e none repeat scroll 0 0;
	color: #fff
}

.theme-btn-white:hover {
	background: #a5a5a5 none repeat scroll 0 0;
	color: #fff
}

.section-title {
	margin-bottom: 50px
}

.section-para {
	margin-bottom: 50px
}

.section-title h2 {
	font-size: 44px;
	font-weight: 700;
	color: #1f1c45;
	text-transform: capitalize
}

.section-title h2 span {
	position: relative
}

.section-title h2 span:after {
	position: absolute;
	content: "";
	left: 0;
	right: 0;
	height: 16px;
	height: 1rem;
	bottom: 7px;
	z-index: -1;
	background-color: #2783a800;
	width: auto;
	opacity: 0.7;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
}

.section-para p {
	color: #111;
	font-weight: 500;
	font-size: 16px
}

.section_100 {
	padding: 100px 0;
}

.section_t_100 {
	padding-top: 100px;
}

.section_b_100 {
	padding-bottom: 100px;
}

.section_70 {
	padding: 70px 0;
}

.section_t_70 {
	padding-top: 70px;
}

.section_b_70 {
	padding-bottom: 70px;
}

.section_50 {
	padding: 50px 0;
}

.section_t_50 {
	padding-top: 50px;
}

.section_b_50 {
	padding-bottom: 50px;
}

.section_15 {
	padding: 15px 0
}

.pad-right {
	padding-right: 0
}

.section_t_100 {
	padding-top: 100px
}

.section_b_70 {
	padding-bottom: 70px
}

.section_70 {
	padding: 70px 0
}

.section_b_80 {
	padding-bottom: 80px
}

.pagination-box-row {
	text-align: center;
}

.pagination-box-row p {
	display: inline-block;
	font-size: 15px;
	color: #444;
	margin-right: 15px
}

.pagination {
	margin-top: 50px;
	text-align: center;
	float: none;
	display: inline-block;
}

.pagination li {
	display: inline-block;
	margin-right: 5px;
	cursor: pointer;
}

.pagination li:last-child {
	margin-right: 0
}

.pagination li a {
	display: block;
	width: 40px;
	height: 40px;
	font-size: 15px;
	line-height: 40px;
	border-radius: 50%;
	color: #111;
	font-weight: 500;
	text-align: center;
	border: 1px solid #fff
}

.pagination li.rc-pagination-item-active a {
	background: #2784a8 none repeat scroll 0 0;
	color: #fff;
	border: 1px solid #2784a8
}

.pagination li.rc-pagination-item-active a:hover {
	border: 1px solid #2784a8
}

.pagination li a:hover {
	border: 1px solid #ddd
}
.pagination > ul{
	display: flex;
	align-items: center;
}
.rc-pagination-disabled{
	display: none !important;
}
.form-group {
    margin-bottom: 1rem;
}
label {
    display: inline-block;
    margin-bottom: 0.5rem;
}
.responsiveMenu{
	display: none;
}

/*Responsive*/
@media only screen and (min-width: 1366px) {
	.container {
		max-width: 1200px;
	}
}

@media only screen and (min-width: 1500px) {
	.container {
		max-width: 1410px;
		padding: 0 20px;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.main-menu ul li {
		margin: 0 10px
	}
	.main-menu ul li a {
		text-transform: capitalize
	}
	.hero-section .hero-bg {
		left: -50%
	}
	.hero-left h2 {
		font-size: 44px
	}
	.hero-section {
		height: 580px
	}
	.choose-box {
		padding: 30px
	}
	.choose-box p {
		font-size: 14px
	}
	.choose-shape-bg {
		top: -30px
	}
	.about-page-left span.mark-text {
		font-size: 160px
	}
	.about-post-text h3 {
		font-size: 22px;
		line-height: 30px
	}
	.error-page-box p {
		width: 70%
	}
	.login-text h2 {
		font-size: 28px
	}
	.login-form {
		width: 80%
	}
	aside .widget {
		padding: 30px 15px 30px 15px
	}
}

@media only screen and (max-width: 991px) {
	.main-menu {
		display: none
	}
	.responsiveMenu{
		display: block;
	}
	.mobile-menu {
		display: block;
		width: 50%
	}
	.right-bar-icon {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		padding-right: 0px;
	}
	.right-bar-icon li {
		padding:  0;
		z-index: 999
	}
	.slicknav_btn {
		margin-top: 13px
	}
	.hero-left h2 {
		font-size: 32px
	}
	.hero-right>img {
		width: 100%;
	}
	.hero-section {
		padding-top: 150px;
		height: auto
	}
	.hero-section .hero-bg {
		top: 0
	}
	.companies-section {
		padding-top: 70px;
	}
	.about-left h3 {
		font-size: 32px
	}
	.about-right {
		margin-top: 30px;
	}
	.about-bottom .about-right {
		margin-top: 0;
		margin-bottom: 30px
	}
	.about-bottom .about-left {
		padding-left: 0
	}
	.about-top {
		padding-bottom: 30px;
	}
	.hero-left h2 span.underline:after,
	.section-title h2 span:after {
		height: 7px
	}
	.service-section .section-title {
		margin-bottom: 30px
	}
	.companies-left {
		margin-bottom: 30px;
	}
	.section-title h2 {
		font-size: 32px
	}
	.subscribe-box form {
		width: 90%
	}
	.breadcrumb-area {
		padding: 160px 0 110px
	}
	.about-page-left span.mark-text {
		font-size: 150px
	}
	.about-page-text {
		margin-top: 30px
	}
	.ui-timline-container {
		width: 100%
	}
	.sponsor-box li {
		width: 19%
	}
	.error-page-box p {
		width: 90%
	}
	.login-section .col-lg-6:first-child {
		display: none
	}
	.login {
		background: #7052fb none repeat scroll 0 0
	}
	.login:before {
		display: none
	}
	.login-right {
		background: #fff;
		padding: 20px;
		border-radius: 30px;
		text-align: center;
		width: 70%;
		margin: 0 auto
	}
	.login-form h3 {
		font-size: 20px
	}
	.login-policy>p {
		padding-right: 6px;
		padding-right: 6px
	}
	.login-form {
		width: 100%;
		margin-top: 25px
	}
	.login-section {
		padding: 150px 0;
	}
	.login-section .col-lg-6 {
		display: block;
		height: auto
	}
	.custom-checkbox {
		text-align: left
	}
	.product-details-text {
		margin-top: 30px
	}
	.product-details-text h3 {
		font-size: 30px
	}
	.order-summury-box {
		margin-top: 30px
	}
	aside {
		margin-top: 50px
	}
	.contact-form-wrapper:before {
		display: none
	}
	.address-area {
		margin-top: 30px;
		width: 100%
	}
	.right-bar-icon ul{
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width:100%
	}
	.header-area{
		padding: 15px 0;
	}
}

@media only screen and (max-width: 767px) {
	.main-menu {
		display: none
	}
	.mobile-menu {
		display: block;
		width: 100%
	}
	.right-bar-icon {
		display: block;
		padding: 0;
	}
	.right-bar-icon ul{
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width:100%
	}
	
	.logo-area {
		position: relative;
		z-index: 99999;
		width: 140px
	}
	.header-area {
		padding: 15px 0
	}
	.slicknav_btn {
		margin-top: 5px;
		padding-top: 0;
	}
	.hero-section {
		padding-top: 160px;
		height: 1300px
	}
	.hero-left h2 {
		font-size: 34px;
		font-weight: 600
	}
	.hero-left h2 span.underline:after {
		height: 6px
	}
	.hero-section .hero-shape5 {
		z-index: -1
	}
	.hero-right>img {
		width: 100%;
		margin-top: 30px;
	}
	.companies-left h4 {
		font-size: 22px;
		margin-bottom: 30px;
	}
	.about-left h3 {
		font-size: 28px
	}
	.about-top .about-right img {
		margin-top: 30px
	}
	.about-top.section_100 {
		padding-bottom: 30px;
	}
	.about-top .about-left {
		padding-right: 0
	}
	.about-bottom .about-left {
		padding-left: 0;
		margin-top: 30px;
	}
	.service-section .section-title {
		margin-bottom: 25px;
	}
	.section-para p {
		color: #3a505f;
		font-weight: 400;
		font-size: 15px;
	}
	.section-title h2 {
		font-size: 30px
	}
	.video-section .section-title p {
		font-size: 16px
	}
	.video-section .section-title {
		width: 100%
	}
	.video-section .section-title h2 {
		font-size: 26px
	}
	.video-section .video-bg-shape {
		height: 550px
	}
	.video-inn {
		width: 100%
	}
	.section-title h2 span:after {
		height: 6px
	}
	.choose-shape-bg {
		top: 35px
	}
	.choose-left ul {
		-webkit-column-count: 1;
		-moz-column-count: 1;
		column-count: 1
	}
	.choose-right .col-lg-6:nth-child(2n+1) {
		-webkit-transform: translateY(0px);
		transform: translateY(0px);
	}
	.choose-box h2 {
		font-size: 30px
	}
	.choose-right .col-lg-6:last-child .choose-box {
		margin-bottom: 0
	}
	.testimonial-pos h3 {
		font-size: 50px;
		letter-spacing: 10px
	}
	.testimonial-body p {
		font-size: 15px
	}
	.subscribe-box form {
		width: 100%
	}
	.subscribe-box p {
		font-size: 16px
	}
	.companies-section {
		padding-top: 0
	}
	.breadcrumb-area {
		padding: 150px 0 100px
	}
	.about-page-left span.mark-text {
		font-size: 130px;
		line-height: 130px
	}
	.about-page-text {
		margin-top: 30px;
	}
	.ui-timeline .tl-item .tl-body {
		display: block;
		width: 100%
	}
	.ui-timeline .tl-item .tl-caption {
		margin-left: -35px
	}
	.tl-caption>p {
		border-radius: 0 50% 50% 0
	}
	.ui-timeline .tl-item .tl-body .tl-content {
		padding: 20px
	}
	.ui-timeline .tl-item .tl-body .tl-content:before {
		display: none
	}
	.ui-timeline .tl-item.alt {
		text-align: left
	}
	.ui-timeline .tl-item.alt .tl-body .tl-entry {
		margin: 0 0 35px 36px;
	}
	.ui-timeline .tl-item.alt .tl-body .tl-icon {
		left: -48px
	}
	.ui-timeline .tl-item.alt .tl-body .tl-content:after {
		-moz-border-bottom-colors: none;
		-moz-border-left-colors: none;
		-moz-border-right-colors: none;
		-moz-border-top-colors: none;
		border-color: rgba(255, 255, 255, 0.9) rgba(255, 255, 255, 0.9) transparent transparent;
		-o-border-image: none;
		border-image: none;
		border-style: solid;
		border-width: 8px;
		content: "";
		left: -16px;
		right: auto;
		line-height: 0;
		position: absolute;
		top: 0;
		display: none
	}
	.ui-timeline .tl-item .tl-body .tl-time {
		display: none
	}
	.ui-timeline:before {
		left: 0;
	}
	.ui-timline-container {
		width: 100%
	}
	.ui-timeline .tl-item:last-child .tl-entry {
		margin-bottom: 0
	}
	.sponsor-box li {
		display: inline-block;
		margin: 30px 0 0;
		width: 100%;
	}
	.sponsor-box-item {
		margin-top: 0
	}
	.error-page-logo {
		text-align: center;
		margin-bottom: 30px;
	}
	.error-page-logo-right {
		text-align: right;
		display: none
	}
	.error_bg {
		font-size: 70px
	}
	.error-page-box h4 {
		font-size: 24px
	}
	.error-page-box p {
		width: 100%
	}
	.login-section .col-lg-6:first-child {
		display: none
	}
	.login {
		background: #7052fb none repeat scroll 0 0
	}
	.login:before {
		display: none
	}
	.login-right {
		background: #fff;
		padding: 20px;
		border-radius: 30px;
		text-align: center;
		width: 100%
	}
	.login-form h3 {
		font-size: 20px
	}
	.login-policy>p {
		padding-right: 6px;
		padding-right: 6px
	}
	.login-form {
		width: 100%;
		margin-top: 25px
	}
	.login-section {
		padding: 70px 0;
	}
	.login-section .col-lg-6 {
		display: block;
		height: auto
	}
	.custom-checkbox {
		text-align: left
	}
	.product-details-text {
		margin-top: 30px
	}
	.product-details-text h3 {
		font-size: 30px
	}
	.single-shop-page-btn {
		display: block
	}
	.single-shop-page-btn ul {
		margin-left: 0;
		margin-top: 20px
	}
	.coupon {
		width: 100%
	}
	.coupon2 {
		float: none;
		margin-top: 20px;
		display: inline-block;
	}
	.order-summury-box {
		margin-top: 30px
	}
	.blog_info .blog-detail {
		padding: 20px
	}
	.blog-page-left .other_info {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex
	}
	.blog-page-left .other_info label {
		text-align: left;
		display: block;
		margin-top: 0px;
	}
	.blog-detail h3 {
		font-size: 24px
	}
	.blog-page-area .pagination {
		margin-top: 20px
	}
	aside {
		margin-top: 50px
	}
	.map-area iframe {
		height: 300px
	}
	.contact-form-wrapper:before {
		display: none
	}
	.address-area {
		margin-top: 30px;
		width: 100%
	}
	.header-area.fixed-header .right-bar-icon li{
		padding: 0;
	}
}

@media only screen and (max-width: 480px) {
	.main-menu {
		display: none
	}
	.mobile-menu {
		display: block;
		width: 100%
	}
	.companies-section {
		padding-top: 0px
	}
	.right-bar-icon {
		display: block;
		padding: 0;
	}
	.right-bar-icon ul{
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width:100%
	}
	.logo-area {
		position: relative;
		z-index: 99999;
		width: 140px
	}
	.header-area {
		padding: 15px 0
	}
	.slicknav_btn {
		margin-top: -5px;
		padding-top: 0;
	}
	.hero-section .hero-bg {
		left: -110%
	}
	.hero-section {
		padding-top: 0px;
		height: 900px
	}
	.hero-left h2 {
		font-size: 28px;
		font-weight: 600
	}
	.hero-left h2 span.underline:after {
		height: 6px
	}
	.hero-section .hero-shape5 {
		z-index: -1
	}
	.hero-right>img {
		width: 100%;
		margin-top: 30px;
	}
	.companies-left h4 {
		font-size: 22px;
		margin-bottom: 30px;
	}
	.about-left h3 {
		font-size: 28px
	}
	.about-top .about-right img {
		margin-top: 30px
	}
	.about-top.section_100 {
		padding-bottom: 30px;
	}
	.about-top .about-left {
		padding-right: 0
	}
	.about-bottom .about-left {
		padding-left: 0;
		margin-top: 30px;
	}
	.service-section .section-title {
		margin-bottom: 25px;
	}
	.section-para p {
		color: #3a505f;
		font-weight: 400;
		font-size: 15px;
	}
	.section-title h2 {
		font-size: 30px
	}
	.video-section .section-title p {
		font-size: 16px
	}
	.video-section .section-title {
		width: 100%
	}
	.video-section .section-title h2 {
		font-size: 26px
	}
	.video-section .video-bg-shape {
		height: 550px
	}
	.video-inn {
		width: 100%
	}
	.section-title h2 span:after {
		height: 6px
	}
	.choose-shape-bg {
		top: 35px
	}
	.choose-left ul {
		-webkit-column-count: 1;
		-moz-column-count: 1;
		column-count: 1
	}
	.choose-right .col-lg-6:nth-child(2n+1) {
		-webkit-transform: translateY(0px);
		transform: translateY(0px);
	}
	.choose-box h2 {
		font-size: 30px
	}
	.choose-right .col-lg-6:last-child .choose-box {
		margin-bottom: 0
	}
	.testimonial-pos h3 {
		font-size: 50px;
		letter-spacing: 10px
	}
	.testimonial-body p {
		font-size: 15px
	}
	.subscribe-box form {
		width: 100%
	}
	.subscribe-box p {
		font-size: 16px
	}
	.breadcrumb-area {
		padding: 150px 0 100px
	}
	.about-page-left span.mark-text {
		font-size: 130px;
		line-height: 130px
	}
	.about-page-text {
		margin-top: 30px;
	}
	.ui-timeline .tl-item .tl-body {
		display: block;
		width: 100%
	}
	.ui-timeline .tl-item .tl-caption {
		margin-left: -35px
	}
	.tl-caption>p {
		border-radius: 0 50% 50% 0
	}
	.ui-timeline .tl-item .tl-body .tl-content {
		padding: 20px
	}
	.ui-timeline .tl-item .tl-body .tl-content:before {
		display: none
	}
	.ui-timeline .tl-item.alt {
		text-align: left
	}
	.ui-timeline .tl-item.alt .tl-body .tl-entry {
		margin: 0 0 35px 36px;
	}
	.ui-timeline .tl-item.alt .tl-body .tl-icon {
		left: -48px
	}
	.ui-timeline .tl-item.alt .tl-body .tl-content:after {
		-moz-border-bottom-colors: none;
		-moz-border-left-colors: none;
		-moz-border-right-colors: none;
		-moz-border-top-colors: none;
		border-color: rgba(255, 255, 255, 0.9) rgba(255, 255, 255, 0.9) transparent transparent;
		-o-border-image: none;
		border-image: none;
		border-style: solid;
		border-width: 8px;
		content: "";
		left: -16px;
		right: auto;
		line-height: 0;
		position: absolute;
		top: 0;
		display: none
	}
	.ui-timeline .tl-item .tl-body .tl-time {
		display: none
	}
	.ui-timeline:before {
		left: 0;
	}
	.ui-timline-container {
		width: 100%
	}
	.ui-timeline .tl-item:last-child .tl-entry {
		margin-bottom: 0
	}
	.sponsor-box li {
		display: inline-block;
		margin: 30px 0 0;
		width: 100%;
	}
	.sponsor-box-item {
		margin-top: 0
	}
	.error-page-logo {
		text-align: center;
		margin-bottom: 30px;
	}
	.error-page-logo-right {
		text-align: center;
		display: none
	}
	.error_bg {
		font-size: 70px
	}
	.error-page-box h4 {
		font-size: 24px
	}
	.error-page-box p {
		width: 100%
	}
	.login-section .col-lg-6:first-child {
		display: none
	}
	.login {
		background: #52cbfb none repeat scroll 0 0
	}
	.login:before {
		display: none
	}
	.login-right {
		background: #fff;
		padding: 20px;
		border-radius: 30px;
		text-align: center;
		width: 100%
	}
	.login-form h3 {
		font-size: 20px
	}
	.login-policy>p {
		padding-right: 6px;
		padding-right: 6px
	}
	.login-form {
		width: 100%;
		margin-top: 25px
	}
	.login-section .col-lg-6 {
		display: block;
		height: auto
	}
	.custom-checkbox {
		text-align: left
	}
	.product-details-text {
		margin-top: 30px
	}
	.product-details-text h3 {
		font-size: 30px
	}
	.single-shop-page-btn {
		display: block
	}
	.single-shop-page-btn ul {
		margin-left: 0;
		margin-top: 20px
	}
	.coupon {
		width: 100%
	}
	.coupon2 {
		float: none;
		margin-top: 20px;
		display: inline-block;
	}
	.order-summury-box {
		margin-top: 30px
	}
	.blog_info .blog-detail {
		padding: 20px
	}
	.blog-page-left .other_info {
		display: block
	}
	.blog-page-left .other_info label {
		text-align: left;
		display: block;
		margin-top: 10px;
	}
	.blog-detail h3 {
		font-size: 24px
	}
	.blog-page-area .pagination {
		margin-top: 20px
	}
	aside {
		margin-top: 50px
	}
	.single-comment-box.comment_reply {
		padding-left: 20px
	}
	.map-area iframe {
		height: 300px
	}
	.contact-form-wrapper:before {
		display: none
	}
	.address-area {
		margin-top: 30px
	}
	.search-overlay .search-overlay-form{
		width:90%
	}
	.header-area.fixed-header .right-bar-icon li{
		padding: 0;
	}
}
