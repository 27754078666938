/* ===================== Cta ======================*/

.trial-section {
	position: relative;
	overflow: hidden;
	z-index: 1;
}

.trial-section:after {
	position: absolute;
	content: "";
	z-index: -1;
	left: 0;
	width: 100%;
	height: 100%;
	border-right: 100vw solid #7052fb;
	border-left: 0px solid #7052fb;
	bottom: 0px;
	border-top: 90px solid #fff;
	border-bottom: 0px solid transparent;
}

.trial-box {
	text-align: center;
	padding-top: 50px
}

.trial-box .section-title {
	margin-bottom: 0
}

.trial-box .section-title  h2 {
	position: relative;
	z-index: 1;
	color: #fff;
	text-transform: capitalize;
}

.trial-box .section-title h2 span:after {
	background-color: #76e42c;
}

.trial-box p {
	color: #fff;
	font-weight: 600;
	text-transform: capitalize;
	font-size: 18px;
	margin: 25px 0;
}
