/* ===================== VIDEO SECTION ====================== */

.video-section {
	background: #fff; /* Set background to white */
	padding: 100px 0;
	position: relative;
  }
  
  .section-title {
	text-align: center;
	margin-bottom: 50px;
  }
  
  .section-title p {
	color: #333;
	font-size: 16px;
  }
  
  .section-title h2 {
	color: #333;
	font-size: 36px;
	font-weight: 700;
  }
  
  .video-holder {
	position: relative;
	overflow: hidden;
	margin-bottom: 50px; /* Add margin to create space between media sections */
  }
  
  .video-inn {
	position: relative;
  }
  
  .video-thumbnail {
	width: 100%;
	border-radius: 8px;
	transition: transform 0.3s;
  }
  
  .video-thumbnail:hover {
	transform: scale(1.05);
  }
  
  .play-button-img {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 60px; /* Adjust the size as needed */
	height: 60px; /* Adjust the size as needed */
	transition: opacity 0.3s;
	opacity: 0.8;
  }
  
  .video-inn:hover .play-button-img {
	opacity: 1;
  }
  
  .video-title {
	margin-top: 20px;
	text-align: center;
	font-size: 24px;
	font-weight: 600;
	color: #1c2045;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
	.video-holder {
	  margin-bottom: 50px; /* Ensure sufficient space between video sections on mobile */
	}
  
	.video-thumbnail {
	  margin-bottom: 20px; /* Add spacing below thumbnail */
	}
  
	.platform-logo {
	  height: 20px; /* Make logos smaller */
	  margin: 0 5px; /* Increase gap between logos */
	}
  }
  
  @media (max-width: 576px) {
	.video-holder {
	  margin-bottom: 60px; /* Increase space between video sections on smaller screens */
	}
  
	.video-thumbnail {
	  margin-bottom: 20px; /* Add spacing below thumbnail */
	}
  
	.platform-logo {
	  height: 16px; /* Make logos smaller */
	  margin: 0 8px; /* Increase gap between logos */
	}
  }
  